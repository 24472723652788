import { getChannel } from "app/actions/channel";
import { getGroupData } from "app/actions/group";
import { useActions, useStoreState } from "app/store";
import * as React from "react";
import { useResourcePermissionWithoutSuperCheck } from "common/components/permissionChecker";
import { POST_SHOW_SCROLL_TO_BOTTOM } from "common/constants/keys";
import { AnalyticsClass } from "common/helpers/analytics/analytics";
import useCancelToken from "common/hooks/useCancelToken";
import PostShowBottom from "./components/bottom";
import ForumShowHeader from "./components/header";
import ThreadShow from "./components/threadShow";
import { defaultShowConfig, PostShowContext } from "./context";
import {
  Divider,
  ForumContainer,
  PostShowBottomWrapper,
  PostShowWrapper,
} from "./styled";

interface IProps {
  isModalShow: boolean;
  post: Moim.Forum.IThread;
  showConfig?: Omit<Moim.Forum.IForumShowConfig, "show_type">;
  className?: string;
  onBack(): void;
  onClickTagItem?(tagItem: Moim.TagSet.ITagItem): void;
}

const PostShow: React.FC<IProps> = ({
  isModalShow,
  post,
  showConfig: showConfigProps,
  className,
  onBack,
  onClickTagItem,
}) => {
  const query = new URLSearchParams(location.search);
  const queryGroupId = query.get("groupId") ?? undefined;
  const forumShowContainerRef = React.useRef<HTMLDivElement>(null);
  const forumShowRef = React.useRef<HTMLDivElement>(null);
  const cancelToken = useCancelToken();

  const { group, channel } = useStoreState(state => ({
    group: post.groupId ? state.entities.groups[post.groupId] : undefined,
    channel: state.entities.channels[post.parent_id] as
      | Moim.Channel.IForumSimpleChannel
      | undefined,
  }));

  const { hasPermission: hasCopyPostPermission } =
    useResourcePermissionWithoutSuperCheck("COPY_POST", post.id);
  const { dispatchGetChannel, dispatchGetGroup } = useActions({
    dispatchGetChannel: getChannel,
    dispatchGetGroup: getGroupData,
  });

  const showConfig = React.useMemo(
    () => showConfigProps ?? channel?.show_config ?? defaultShowConfig,
    [showConfigProps, channel?.show_config],
  );

  const contextValue = React.useMemo(
    () => ({
      isModalShow,
      post,
      showConfig,
      containerRef: forumShowContainerRef,
      forumShowRef,
      onBack,
    }),
    [isModalShow, post, showConfig, onBack],
  );

  React.useEffect(() => {
    if (!group && post.groupId) {
      dispatchGetGroup(post.groupId);
    }
    if (!channel) {
      dispatchGetChannel(
        { channelId: post.parent_id },
        cancelToken.current.token,
        queryGroupId,
      );
    }
  }, [post.id]);

  React.useEffect(() => {
    if (channel) {
      AnalyticsClass.getInstance().forumPostView({
        forumId: channel?.id ?? "",
        forumName: channel?.name ?? "",
        postId: post.id,
        postTitle: post.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id]);

  React.useLayoutEffect(() => {
    const shouldScrollToBottom =
      sessionStorage.getItem(POST_SHOW_SCROLL_TO_BOTTOM) === "true";

    const scrollToBottom = () => {
      forumShowContainerRef?.current?.scrollIntoView({
        block: "end",
        behavior: "auto",
      });
    };

    const scrollToTop = () => {
      forumShowContainerRef?.current?.scrollIntoView({
        block: "start",
        behavior: "auto",
      });
    };

    let observer: ResizeObserver | undefined;
    const timeoutId = setTimeout(() => {
      if (shouldScrollToBottom) {
        scrollToBottom();

        observer = new ResizeObserver(() => {
          scrollToBottom();
        });

        if (forumShowContainerRef?.current) {
          observer.observe(forumShowContainerRef.current);
        }
      } else {
        scrollToTop();
      }
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      if (forumShowContainerRef?.current && !!observer) {
        observer.unobserve(forumShowContainerRef.current);
      }
      sessionStorage.removeItem(POST_SHOW_SCROLL_TO_BOTTOM);
    };
  }, []);

  return (
    <PostShowContext.Provider value={contextValue}>
      <ForumContainer
        className={className}
        ref={forumShowContainerRef}
        hasCopyPostPermission={hasCopyPostPermission}
      >
        <ForumShowHeader />
        <PostShowWrapper ref={forumShowRef}>
          <ThreadShow followerCount={0} onClickTagItem={onClickTagItem} />
        </PostShowWrapper>

        {showConfig.show_comment_area && <Divider />}

        <PostShowBottomWrapper>
          <PostShowBottom />
        </PostShowBottomWrapper>
      </ForumContainer>
    </PostShowContext.Provider>
  );
};

export default PostShow;
